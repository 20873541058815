import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import React from "react"
import useStations from "../utils/hooks/useStations"

const PlacementGuidePage = () => {
  const { station } = useStations()
  return (
    <Layout station={station}>
      <Seo title="Weather Station Placement Guide" />

      <div className="container px-3 mx-auto container-article xl:pt-6 lg:px-4 xl:px-12">
        <div className="grid grid-cols-1 gap-6 mb-8 lg:mb-12 lg:grid-flow-col xl:gap-12 md:grid-cols-12">
          <div className="md:col-span-8">
            <h1>Weather Station Placement Guide</h1>

            <p className="intro">
              Proper weather station placement on your farm keeps weather data
              flowing seamlessly into NEWA.
            </p>

            <p>
              The weather data collected and delivered via NEWA is valued for
              crop production and IPM forecast applications. The correct set-up
              of the weather station will insure that the highest quality data
              is collected on-site. This page has information about optimal
              weather station placement. NEWA’s{" "}
              <Link to="/maintenance-guide">Maintenance Guide</Link> page has
              information on maintaining a weather station, which is equally
              important to the continuing quality of the weather data.
            </p>

            <p>
              Farm locations vary to such a degree that it is not possible to
              meet a formal set of guidelines in all locations. However, as much
              as is feasible at each location, the placement of weather stations
              should come as close as possible to meeting National Weather
              Service (NWS) and weather station manufacturer specifications.
              Visit{" "}
              <Link to="/about-weather-stations">About Weather Stations</Link>{" "}
              and <Link to="/buy-a-weather-station">Buy a Weather Station</Link>{" "}
              for vendor contact information and additional details.
            </p>

            <hr></hr>
            <h2>Site Considerations</h2>

            <p>
              Variations in siting NEWA weather stations may result from the
              following:
            </p>

            <ul>
              <li>objectives of the user</li>
              <li>commodity type (i.e. orchard vs. field)</li>
              <li>farm structures</li>
              <li>terrain</li>
              <li>cable length limits</li>
              <li>radio frequency limits</li>
              <li>data transmission method (cellular, Wi-Fi, etc.)</li>
            </ul>

            <p>
              For orchards, vineyards, and fields it is optimal to place the
              weather station in an open area and not within the planting. If
              placement is near the edge of the orchard, vineyard or field, make
              sure that tree or trellis height is well below the wind and rain
              gauges. Protect weather instruments from spray applications
              because this may damage the sensors or cause erroneous readings.
            </p>

            <hr></hr>
            <h2>Sensor considerations</h2>

            <h3>
              Official NWS regulations on siting temperature and precipitation
              sensors
            </h3>

            <div className="pl-4 mt-10 mb-10 border-l-2 lg:pl-6">
              <h4>Temperature sensors</h4>

              <ul>
                <li>
                  The sensor should be 4 to 6 feet, preferably 5 feet, above the
                  ground.
                </li>
                <li>
                  The ground over which the radiation shield is located should
                  be typical of the surrounding area.
                </li>
                <li>
                  Do not install the sensor on a steep slope or in a sheltered
                  hollow unless it is typical of the area or unless data from
                  that type of site is desired.
                </li>
                <li>
                  The shield should be no closer than four times the height of
                  any obstruction (tree, fence, building, etc.)
                </li>
                <li>
                  The sensor should be at least 100 feet from any paved or
                  concrete surface.
                </li>
              </ul>
            </div>

            <div className="pl-4 mb-10 border-l-2 lg:pl-6">
              <h4>Rain gauges</h4>

              <ul>
                <li>
                  The tipping bucket must be installed as level as possible.
                </li>
                <li>
                  The exposure of a rain gauge is very important for obtaining
                  accurate measurements.
                </li>
                <li>
                  Gauges should be located away from isolated obstructions such
                  as trees and buildings, which may deflect precipitation due to
                  erratic turbulence.
                </li>
                <li>
                  To avoid wind and resulting turbulence problems, do not locate
                  gauges in wide open spaces or on elevated sites, such as the
                  tops of buildings.
                </li>
                <li>
                  The best site for a gauge is one in which it is protected in
                  all directions, such as in an opening in a grove of trees. The
                  height of the protection should not exceed twice its distance
                  from the gauge.
                </li>
                <li>
                  As a general rule, the windier the gauge location is, the
                  greater the precipitation error will be.
                </li>
              </ul>
            </div>

            <hr></hr>
            <h3>NEWA guidelines</h3>

            <div className="pl-4 mt-10 mb-10 border-l-2 lg:pl-6">
              <h4>Rain gauges</h4>

              <p>
                The surrounding environment will affect how often it will need
                cleaning, so make sure the tipping bucket rain gauge is not
                subject to filling with leaf litter from adjacent areas. Make
                certain the rain gauge is securely in place. If improperly
                mounted, the weather station may be jostled during strong winds,
                which can cause the tipping bucket arm to tip and erroneously
                record precipitation during dry weather.
              </p>
            </div>

            <div className="pl-4 mb-10 border-l-2 lg:pl-6">
              <h4>Leaf wetness sensors</h4>

              <p>
                Place leaf wetness sensors in the open area with the weather
                station. This protects the sensor and cables from pruning,
                spraying and harvesting activities. Leaf wetness sensors of the
                plastic grid type should be placed facing north and angled 45
                degrees from horizontal. Most are already positioned this way on
                the weather station unit.
              </p>
            </div>

            <div className="pl-4 mb-10 border-l-2 lg:pl-6">
              <h4>Solar radiation</h4>

              <p>
                The weather station should not be subject to shade during any
                part of the day, otherwise incorrect solar radiation readings
                will result. Always place it in an open area where it will
                receive sunlight for the full daylength duration.
              </p>
            </div>

            <p>
              First and foremost, follow manufacturer guidelines wherever
              possible. As anomalies are identified, adjustments in siting or
              shielding can be made. Often these anomalies are found by
              comparison to other sensors placed on the site or to other weather
              stations in the vicinity.
            </p>

            <footer className="pt-6 mt-12 text-base border-t border-gray-200">
              <p>Page updated April 2020 by J. Carroll and D. Olmstead</p>
            </footer>
          </div>
          <div className="pt-4 text-base text-gray-700 border-t-2 border-gray-200 md:col-span-4 md:border-l-2 md:border-t-0 md:pt-3 md:pl-4 xl:pl-8">
          <h2>Get a Weather Station</h2>
            <p>
              NEWA is compatible with special configurations of {' '}
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">
                Onset 
              </Link>{' '}
              and <Link to="https://kestrelmet.com/kestrelmet-6000-ag-weather-station-newa">KestrelMet</Link> weather instruments. See what your options are.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Buy a Weather Stations"
                to="/buy-a-weather-station"
              >
                Buy a Weather Station
              </Link>
            </p>

            <hr></hr>

            <h2>Have a question?</h2>
            <p>
              Reach out to our support center and ask questions to get support for NEWA tools, resources, and weather
              stations.
            </p>
            <p>
              <a
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                href="mailto:support@newa.zendesk.com"
              >
                Contact the NEWA Help Desk
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PlacementGuidePage
